<template>
  <div>
  <span class="label">
    {{ label }}
  </span>
    <el-autocomplete
      style="width: 100%"
      size="mini"
      v-model="model"
      :disabled="disabled"
      :placeholder="placeholder"
      :fetch-suggestions="search"
      :trigger-on-focus="false"
      @focus="onFocus"
      @change="$emit('input', resultedValue)"
    >
      <template slot-scope="{ item }">
        {{ item.house }}
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'HouseField',
  inject: ['getPreviousValue'],
  props: {
    label: {
      type: String
    },
    type: {
      type: String
    },
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: Object
    }
  },
  data () {
    return {
      model: null,
      options: [],
      isLoaded: false
    }
  },
  methods: {
    async load () {
      let data = await this.$http.get(`${this.$config.api}/fiasaddressservice/addresses/houses/${this.previousValue.guid}/house/`)
      this.options = data.data.map((item) => {
        return {
          house_houseid: item.house_houseid,
          house: item.name
        }
      })
      this.isLoaded = true
    },
    onFocus () {
      if (!this.isLoaded) {
        this.load()
      }
    },
    async search (query, callback) {
      let results = query ? this.options.filter((item) => item.house.toLowerCase().indexOf(query.toLowerCase()) === 0) : this.options
      callback(results)
    }
  },
  computed: {
    resultedValue () {
      if (!this.model) {
        return null
      }
      let object = {}
      let issetHouse = this.options.find((item) => item.house === this.model)
      if (issetHouse) {
        object.house_houseid = issetHouse.house_houseid
      }
      object.house = this.model

      object.previous = this.previousValue.id

      return object
    },
    disabled () {
      return !((this.type === 'subject') || this.previousValue)
    },
    previousValue () {
      return this.getPreviousValue(this.type)
    }
  },
  watch: {
    value: {
      deep: true,
      handler (value) {
        if (value && value.house_houseid && this.options.findIndex((item) => item.house_houseid === value.house_houseid) === -1) {
          this.options.push(value)
        }
        this.model = value && value.house ? value.house : null
        this.$emit('input', this.resultedValue)
      }
    },
    previousValue () {
      this.$emit('input', null)
      this.model = null
      this.isLoaded = false
    }
  }
}
</script>

<style scoped>
.label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #7D7D7D;
  display: block;
  padding: 5px 5px 0px 5px;
}
</style>
